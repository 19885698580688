import axios from "axios";

const schoolinfoapi = "https://das.boxuanedu.cn/dd/rest/schoolInfo/export";
const exmataginfoapi = "https://das.boxuanedu.cn/dd/rest/courseCata/export";
const queryexaminfoapi = "https://das.boxuanedu.cn/dd/rest/courseDetail/list";

/* 获取省份，分校信息 */
function getschoolinfo() {
  if (sessionStorage.getItem("schools")) {
    // this.schools = JSON.parse(sessionStorage.getItem("schools"));
    return;
  } else {
    getinfoapi(schoolinfoapi, "schools");
  }
}

/* 获取考试信息的分类 */
function getexaminfo() {
  if (sessionStorage.getItem("exmatag")) {
    // this.schools = JSON.parse(sessionStorage.getItem("schools"));
    return;
  } else {
    getinfoapi(exmataginfoapi, "exmatag");
  } 
}

/* 考试信息查询 */
async function queryexaminfo(cataId, province,startPage=0) {

  const params = {
    pageNum: 10,
    startPage,
    cataId,
    province
  };

  const res=await axios.post(queryexaminfoapi,params)
  return res


}

function getinfoapi(apiurl, storaname, param = null) {
  axios
    .post(apiurl, param)
    .then((res) => {
      sessionStorage.setItem(storaname, JSON.stringify(res.data.data.list));
      return res.data.data.list;
    })
    .catch((err) => console.log(err));
}




export { getschoolinfo, getexaminfo, queryexaminfo };
