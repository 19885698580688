<template>
  <div class="item">
    <img class="itemimg" :src="data.img" alt="typelesson" />
    <p class="itemtitle">{{ data.lessontitle }}</p>
    <img class="authimg" :src="data.auth" alt="auth" />
    <p class="authp">{{ data.authname }}</p>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    required: true,
  },
};
</script>

<style lang="less" scoped>
.item {
  position: relative;
  .itemimg {
    width: 113px;
    height: 124px;
    position: relative;
  }
  .itemtitle {
    font-size: 8px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #141414;
    position: absolute;
    top: 70px;
    left: 10px;
  }
  .authimg {
    width: 16px;
    height: 15px;
    position: absolute;
    top: 90px;
    left: 10px;
  }
  .authp {
    display: inline-block;
    font-size: 6px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #b4b4b4;
    position: absolute;
    top: 92px;
    left: 30px;
  }
}
</style>
