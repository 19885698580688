<template>
  <div class="payfinish">
    <div class="back" @click="backtolessoninfo(code, 'all')">
      <img src="https://oss.oxke.net/boxuanedu-m/lesson/pay/icon1.png" alt="icon" class="icon" />
      <p class="title">课程详情</p>
    </div>

    <div class="pass">
      <img src="https://oss.oxke.net/boxuanedu-m/lesson/pay/icon2.png" alt="icon" class="icon" />
      <div class="info">
        <p class="top">报名成功</p>
        <div class="bottom">
          点击下方跳转按钮添
          <p class="blue">添加助教</p>
          即可开通权限
        </div>
      </div>
    </div>

    <div class="line"></div>

    <div class="lessoninfo">
      <img :src="lessoninfo.img" alt="lessonimg" class="img" />
      <div class="info">
        <p class="title">{{ lessoninfo.lessontitle }}</p>
        <div class="tag">系统课</div>
        <div class="des m2">
          <p>报名时间</p>
          <p>{{ time }}</p>
        </div>
        <div class="des">
          <p>课程有效期</p>
          <p>永久有效</p>
        </div>
      </div>
    </div>

    <div class="line"></div>

    <p class="title" style="color: #1b60ff">助教链接</p>
    <p class="commongray">{{ endlink }}</p>
    <img src="https://oss.oxke.net/boxuanedu-m/lesson/pay/bg1.png" alt="bg" class="bg" />
    <img src="https://oss.oxke.net/boxuanedu-m/lesson/pay/add.png" alt="add" class="add" />
    <div class="addp">
      点击
      <p style="color: #306dfd">一键添加</p>
      按钮跳转
      <p style="color: #306dfd">微信</p>
      ，添加助教
    </div>

    <div class="line"></div>

    <div class="btn1" @click="opensmslik">一键添加</div>

    <div class="btn2" @click="tohome">返回首页</div>
  </div>
</template>

<script>
import { bxlessons } from "@/tempdata/lesson/lessons";
export default {
  data() {
    return {
      paycode: "",
      lessoninfo: "",
      time: "",
      smscode: "",
      endlink: "",
    };
  },
  methods: {
    aysurl() {
      this.paycode = this.$route.query.code;
      this.smscode = this.$route.query.smsCode;
      this.endlink = "https://t.boxuanedu.cn/" + this.smscode;

      // console.log(this.$route.params);
      if (this.$route.params.link) {
        this.endlink=this.$route.params.link
      }

      // console.log(this.$route,this.smslink);
      //   this.lessoninfo = bxlessons[0];
      for (const item of bxlessons) {
        if (this.paycode == item.paycode) {
          this.lessoninfo = item;
          return;
        }
      }
    },
    /* 跳转到对应课程详情 */
    backtolessoninfo() {
      if (this.code == "EDDn") {
        this.$router.push({
          name: "pubcourse",
          // query: { code: this.code },
        });
      } else {
        this.$router.push({
          name: "lessoninfo",
          query: { code: this.code },
        });
      }
    },
    setdate() {
      Date.prototype.Format = function (fmt) {
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "H+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          S: this.getMilliseconds(), //毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
        return fmt;
      };
      const date = new Date().Format("yyyy-MM-dd HH:mm:ss");
      const name = "buytime" + this.paycode;
      if (sessionStorage.getItem(name)) {
        this.time = sessionStorage.getItem(name);
      } else {
        this.time = date;
        sessionStorage.setItem(name);
      }
    },
    /* 跳转到首页 */
    tohome() {
      this.$router.push({ name: "Home" });
    },
    /* 打开smslimk */
    opensmslik() {
      window.location.href = this.endlink;
    },
  },
  created() {
    this.aysurl();
    this.setdate();
  },
  mounted() {
    setTimeout(() => {
      window.location.href = this.endlink;
    }, 2000);
  },
};
</script>

<style lang="less" scoped>
.payfinish {
  padding: 11px;
  .back {
    display: flex;
    margin-bottom: 21px;
    align-items: center;
    .icon {
      width: 24px;
      height: 24px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #e6e6e6;
      border-radius: 50%;
      margin-right: 9px;
    }
  }

  .pass {
    display: flex;
    .icon {
      width: 33px;
      height: 33px;
      margin-right: 9px;
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        font-size: 15px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #141414;
      }
      .bottom {
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #818181;
        display: flex;
        .blue {
          color: #306dfd;
        }
      }
    }
  }

  .lessoninfo {
    display: flex;
    .img {
      width: 145px;
      height: 82px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      margin-right: 7px;
    }
    .info {
      width: 196px;
      height: 82px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        width: 196px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #141414;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .tag {
        width: 45px;
        height: 19px;
        background: #c6dffb;
        border-radius: 4px 4px 4px 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3px;
        margin-bottom: 5px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1b60ff;
      }
      .des {
        width: 100%;
        display: flex;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #979797;
        justify-content: space-between;
      }

      .m2 {
        margin-bottom: 3px;
      }
    }
  }

  .bg {
    width: 350px;
    height: 160px;
    border-radius: 4px 4px 4px 4px;
  }
  .add {
    width: 82px;
    object-fit: contain;
    margin-top: 12px;
  }
}

.title {
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: 600;
  color: #141414;
}

.commongray {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #5d5d5d;
  margin-top: 2px;
  margin-bottom: 14px;
}

.line {
  width: 350px;
  height: 0px;
  opacity: 1;
  border: 1px solid #eeeeee;
  margin: 16px 0 16px 0;
  border-radius: 50%;
}

.addp {
  display: flex;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #606060;
  margin-top: 7px;
}

.btn1 {
  width: 350px;
  height: 47px;
  background: linear-gradient(180deg, #066cff 0%, #217bfe 100%);
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.btn2 {
  width: 350px;
  height: 47px;
  background: #fcfcfc;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
  border: 1px solid #dbdbdb;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #141414;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
