<template>
  <div class="examinfo">
    <div class="exam">
      <hometitle :data="hometitles[0]"></hometitle>

      <div class="examtags">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="value1"
            :options="eaxmtag"
            @change="getexamtag"
          />
          <van-dropdown-item
            v-model="value2"
            :options="schools"
            @change="getexamprovice"
          />
        </van-dropdown-menu>
      </div>

      <div
        class="examinfom"
        v-for="(item, index) in examinfolist"
        :key="index"
        @click="todetail('examinfodetail', item.id)"
      >
        <div class="item">
          <p class="itemtitle">{{ item.title }}</p>
          <p class="itemtime">{{ item.signSTime }}~{{ item.signETime }}</p>
          <div class="itemposition">
            <div class="tag">{{ item.cataName }}·{{ item.province }}</div>
            <div class="todo"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DropdownMenu, DropdownItem, Notify } from "vant";
import hometitle from "../components/hometitle.vue";
/* 临时数据 */
import { hometitles } from "@/tempdata/home/homelessons";
export default {
  components: {
    [Notify.name]: Notify,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    hometitle,
  },
  data() {
    return {
      hometitles,
    };
  },
};
</script>

<style lang="less" scoped>
.examinfo {
  .exam {
    .examtags {
      width: 100%;
      padding: 12px 10px 0 10px;
      /deep/.van-dropdown-menu__bar {
        position: relative;
        display: flex;
        height: 8.8vw;
        background-color: #f5f5f5;
        box-shadow: none;
        border-radius: 2.133333vw;
      }
    }

    .examinfom {
      padding: 12px 10px 0px 10px;
      .item {
        border: 1px solid #dcebfd;
        border-radius: 12px;
        padding: 10px;
        .itemtitle {
          color: #020b10;
          font-weight: 500;
          font-size: 16px;
          font-family: "Roboto", sans-serif;
          margin-bottom: 8px;
        }
        .itemtime {
          font-weight: 400;
          margin-bottom: 10px;
          font-size: 12px;
          color: #0935d6;
        }
        .itemposition {
          display: flex;
          border-radius: 4px 4px 4px 4px;

          font-size: 10px;
          color: #565656;
          .tag {
            border-radius: 4px;
            padding: 5px;
            background: #e6e6e6;
          }
        }
      }
    }
  }
}
</style>
