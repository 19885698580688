<template>
  <div class="header">
    <img class="ourlogo" src="../../assets/logo/logotitle.png" alt="logo" />
    <van-popover
      v-model="showPopover"
      trigger="click"
      :actions="actions"
      placement="left-start"
      class="nav"
      @select="to"
    >
      <template #reference>
        <img
          class="navpanple"
          src="@/assets/img/home/icon/navback.png"
          alt="nav"
        />
      </template>
    </van-popover>
  </div>
</template>

<script>
import { Popover } from "vant";
export default {
  data() {
    return {
      showPopover: false,
      actions: [
        { text: "首页" },
        { text: "师资信息" },
        { text: "关于我们" },
        { text: "加入我们" },
      ],
    };
  },
  components: {
    [Popover.name]: Popover,
  },
  methods: {
    /* 主页导航 */
    to(actions, index) {
      if (index == 0) {
        this.$router.push({ name: "Home" });
      }
      if (index == 1) {
        this.$router.push({ name: "teacher" });
      }
      if (index == 2) {
        this.$router.push({ name: "about" });
      }
      if (index == 3) {
        this.$router.push({ name: "recruit" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 48px;
  background: hsla(0, 0%, 100%, 1);
  backdrop-filter: blur(10px);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  .ourlogo {
    width: 120px;
    margin-left: 10px;
    position: relative;
  }
  .nav {
    position: relative;
    right: 10px;
    top: 2px;
    .navpanple {
      width: 25px;
      left: 12px;
    }
  }
}
</style>
