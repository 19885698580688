<template>
  <div class="home">
    <div class="topcontent">
      <img class="ourlogo" src="../assets/logo/logotitle.png" alt="logo" />
      <van-search placeholder="请输入搜索关键词" />

      <van-popover
        v-model="showPopover"
        trigger="click"
        :actions="actions"
        placement="left-start"
        class="nav"
        @select="to"
      >
        <template #reference>
          <img
            class="navpanple"
            src="https://oss.oxke.net/boxuanedu-m/home/icon/nav.png"
            alt="nav"
          />
        </template>
      </van-popover>
    </div>

    <div class="topbanner">
      <router-link :to="{ name: 'recruit' }">
        <img
          class="imgbanner"
          src="https://oss.oxke.net/boxuanedu-m/home/1.png"
          alt="bg"
        />
      </router-link>
    </div>

    <div class="tagsbanner">
      <van-swipe indicator-color="black">
        <van-swipe-item>
          <div class="tagcontent">
            <div
              class="item"
              v-for="(item, index) in tagsbanner1"
              :key="index"
              @click="tolesson(item.title)"
            >
              <img class="tagimg" :src="item.img" alt="tagimg" />
              <p>{{ item.title }}</p>
            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="tagcontent">
            <div
              class="item"
              v-for="(item, index) in tagsbanner2"
              :key="index"
              @click="tolesson(item.title)"
            >
              <img class="tagimg" :src="item.img" alt="tagimg" />
              <p>{{ item.title }}</p>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="exam">
      <hometitle :data="hometitles[0]"></hometitle>

      <div class="examtags">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="value1"
            :options="eaxmtag"
            @change="getexamtag"
          />
          <van-dropdown-item
            v-model="value2"
            :options="schools"
            @change="getexamprovice"
          />
        </van-dropdown-menu>
      </div>

      <div
        class="examinfom"
        v-for="(item, index) in examinfolist"
        :key="index"
        @click="todetail('examinfodetail', item.id)"
      >
        <div class="item">
          <p class="itemtitle">{{ item.title }}</p>
          <p class="itemtime">{{ item.signSTime }}~{{ item.signETime }}</p>
          <div class="itemposition">
            <div class="tag">{{ item.cataName }}·{{ item.province }}</div>
            <div class="todo"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="line"></div> -->

    <!-- <div class="try">
      <hometitle :data="hometitles[1]"></hometitle>
      <div class="trycontent">
        <div v-for="(item, index) in trylessons" :key="index">
          <trylessoncard :data="item"></trylessoncard>
        </div>
      </div>
    </div> -->

    <!-- <div class="line"></div>

    <div class="prefer">
      <hometitle :data="hometitles[6]"></hometitle>

      <div class="preferlessoncontent">
        <div
          v-for="(item, index) in limitlessons"
          :key="index"
          @click="tolessoninfo(item.paycode)"
        >
          <preferlessoncard :data="item"></preferlessoncard>
        </div>
      </div>
    </div>

    <div class="prefer">
      <hometitle :data="hometitles[2]"></hometitle>

      <div class="preferlessoncontent">
        <div
          v-for="(item, index) in preferlessons"
          :key="index"
          @click="tolessoninfo(item.paycode)"
        >
          <preferlessoncard :data="item"></preferlessoncard>
        </div>
      </div>
    </div>

    <div class="line"></div>

    <div class="hot">
      <hometitle :data="hometitles[3]"></hometitle>
      <div class="hotcontent">
        <div
          v-for="(item, index) in hotlessons"
          :key="index"
          @click="tolessoninfo(item.paycode)"
        >
          <hotlessoncard :data="item"></hotlessoncard>
        </div>
      </div>
    </div> -->

    <div class="line"></div>

    <div class="teachersintro">
      <hometitle :data="hometitles[4]"></hometitle>

      <div class="swipecontent">
        <van-swipe
          class="my-swipe"
          :autoplay="3000"
          indicator-color="black"
          :show-indicators="false"
        >
          <van-swipe-item>
            <img
              class="hotimg"
              src="https://oss.oxke.net/boxuanedu-m/home/teachersintro/1.png"
              alt="hotimg"
            />
          </van-swipe-item>
          <van-swipe-item>
            <img
              class="hotimg"
              src="https://oss.oxke.net/boxuanedu-m/home/teachersintro/2.png"
              alt="hotimg"
            />
          </van-swipe-item>
          <van-swipe-item>
            <img
              class="hotimg"
              src="https://oss.oxke.net/boxuanedu-m/home/teachersintro/3.png"
              alt="hotimg"
            />
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="publicizecontent">
        <div class="item">
          <div class="inforp">
            <div class="num colorblue">
              90+
              <p class="numafter">专职教研技术大牛</p>
            </div>
            <div class="commonp commbottom">
              <div class="common">高学历</div>
              <div class="commonafter">博士、硕士研究生、985</div>
            </div>
            <div class="commonp">
              <div class="common">大厂背景</div>
              <div class="commonafter">粉笔、华图、中公</div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="inforp">
            <div class="num colororg">
              800+
              <p class="numafter">严选教学团队</p>
            </div>
            <div class="commonp commbottom">
              <div class="common">10年教育经验</div>
              <div class="commonafter">经教学、擅授课</div>
            </div>
            <div class="commonp">
              <div class="common">4大教学法</div>
              <div class="commonafter">讲透知识重难点</div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="inforp">
            <div class="num colorgreen">
              严控
              <p class="numafter">师资及授课质量</p>
            </div>
            <div class="commonp commbottom">
              <div class="common">年薪百万</div>
              <div class="commonafter">持续引进资深讲师人才</div>
            </div>
            <div class="commonp">
              <div class="common">16级标准严选</div>
              <div class="commonafter">老师录取率小于3%</div>
            </div>
          </div>
        </div>
      </div>

      <div class="line"></div>
    </div>

    <div class="ourinfo">
      <hometitle :data="hometitles[5]"></hometitle>

      <div class="infocontent">
        <p class="aim">
          博轩作为积极推动「互联网+教育」的职业培训平台
          力求降低教育成本，用技术实现教育公平
        </p>
        <img
          src="https://oss.oxke.net/boxuanedu-m/home/our/p1.png"
          alt="ourimg"
        />
        <p class="ourdes">
          博轩公考不仅致力于为学员提供高质量的标准化学习产品，更关注公考学习的核心提升路径，从公考学习的核心需求出发，为学员提供个性化、精细化的学习过程管理服务，在高强度、高密度的备考学习过程中，为学员解决学习效率低、分数提升难、做题速度慢、难以坚持等公考问题，充分调动每一位公考学员的学习动力和兴趣，帮助学员更好的上岸！
        </p>

        <p class="infotitle">覆盖业务</p>
        <p class="businessp">
          博轩主营公务员考试，同时拓展有事业单位招聘、教师招聘、考研、财会、英语四六级、法考、建造、公开课等多个领域。
        </p>

        <p class="businessp">
          自品牌建立至今，博轩坚持以人为本的经营理念，以高水平教学质量和优质服务，赢得了用户的信任与尊重。
        </p>

        <div class="tagcontent">
          <div class="tag">
            <img
              class="tagimg"
              src="https://oss.oxke.net/boxuanedu-m/home/our/icon/icon1"
              alt="tag"
            />
            <div class="taginfo">
              <p class="up">公考过来人</p>
              <p class="bottom">真正懂公考备考</p>
            </div>
          </div>

          <div class="tag">
            <img
              class="tagimg"
              src="https://oss.oxke.net/boxuanedu-m/home/our/icon/icon2"
              alt="tag"
            />
            <div class="taginfo">
              <p class="up">成绩优秀</p>
              <p class="bottom">经过时间检验</p>
            </div>
          </div>

          <div class="tag">
            <img
              class="tagimg"
              src="https://oss.oxke.net/boxuanedu-m/home/our/icon/icon3"
              alt="tag"
            />
            <div class="taginfo">
              <p class="up">私教硕士学历</p>
              <p class="bottom">专业个性化辅导</p>
            </div>
          </div>

          <div class="tag">
            <img
              class="tagimg"
              src="https://oss.oxke.net/boxuanedu-m/home/our/icon/icon4"
              alt="tag"
            />
            <div class="taginfo">
              <p class="up">课前课中课后</p>
              <p class="bottom">管家式私教服务</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg">
        <img src="@/assets/logo/logotitle.png" alt="" />
        <p>创新教育 成就每一个人</p>
      </div>

      <div class="ourintro">
        <div class="smallintro">
          <p class="title">公司简介</p>
          <p class="theintro">
            博轩公考团队始建于2019年，目前已形成集公务员、参公管理事业单位、事业单位工作人员等各类公职人员录用考试教育教学研究、考前培训，公职类考试辅导线上线下于一体的知识产业，公司由一批拥有十年教育培训经验的精英团队创办，负责研究、开发教学模式和课程内容。公司具有完善的课程研发体系，一直走在整个行业发展的前端，在行业内树立了良好的品质口碑。
          </p>
        </div>

        <div class="ourdirection">
          <img
            class="theimg"
            src="https://oss.oxke.net/boxuanedu-m/home/our/icon/icon5"
            alt="tag"
          />
          <div class="theinfo">
            <p class="title">企业使命：</p>
            <p class="info">
              让每个人都能公平和便利地获取优质教育服务，并实现可持续成长。
            </p>
          </div>
        </div>

        <div class="ourdirection">
          <img
            class="theimg"
            src="https://oss.oxke.net/boxuanedu-m/home/our/icon/icon6"
            alt="tag"
          />
          <div class="theinfo">
            <p class="title">企业愿景：</p>
            <p class="info">
              打造全球顶尖的人才公司，创办一所国际知名大学，成为令人尊敬的企业。
            </p>
          </div>
        </div>

        <div class="ourdirection">
          <img
            class="theimg"
            src="https://oss.oxke.net/boxuanedu-m/home/our/icon/icon7"
            alt="tag"
          />
          <div class="theinfo">
            <p class="title">企业宗旨：</p>
            <p class="info">
              坚持做经得住时间检验的事情、极致敢为、坦诚开放、知行合一、拥抱变化、始终创业。
            </p>
          </div>
        </div>

        <div class="ourdirection">
          <img
            class="theimg"
            src="https://oss.oxke.net/boxuanedu-m/home/our/icon/icon8"
            alt="tag"
          />
          <div class="theinfo">
            <p class="title">企业价值观：</p>
            <p class="info">
              始终坚持教育本质；始终坚持以学员为核心；始终坚持好老师、好产品；始终专注于教学口碑。
            </p>
          </div>
        </div>
      </div>

      <div class="infocontent">
        <p class="aim">
          时至今日，博轩已经成为万千学子的选择，而我们也将继续在公考教育的道路上做到最好
        </p>

        <p class="infotitle">电视采访</p>
        <p class="businessp">
          博轩公考团队始建于2019年，目前已形成集公务员、参公管理事业单位、事业单位工作人员等各类公职人员录用考试教育教学研究、考前培训，公职类考试辅导线上线下于一体的知识产业。
        </p>

        <video
          controls="controls"
          name="media"
          style="border-radius: 4px;"
        >
          <source
            src="https://oss.oxke.net/boxuanedu/interview.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>

    <!-- <div class="footerer">
      <p>鄂ICP备2023002056号-1</p>
      <p>本站由 博轩数据中心 提供用户服务和技术支持</p>
      <p>Copyright © 2020-2023 All Rights</p>
      <p>博轩公考 武汉博轩教育科技有限公司 版权所有</p>
    </div> -->

    <myfooter></myfooter>

    <!-- <van-dialog
      style="background: transparent"
      v-model="showposter"
      :showConfirmButton="false"
    >
      <div class="poster">
        <img
          class="posterimg"
          src="https://oss.oxke.net/boxuanedu-m/home/poster.png"
          alt="poster"
        />
        <img
          class="postercloseimg"
          src="https://oss.oxke.net/boxuanedu-m/home/postercolse.png"
          alt="posterclose"
          @click="showposter = false"
        />
        <div class="content">
          <h1>
            年薪
            <span class="keyspan">100万</span>
            起
          </h1>

          <div class="info"></div>
          <p>寻<span style="color: #0036ff">公考</span> 各类目老师</p>
          <div class="btn" @click="postergo">了解更多 > ></div>
        </div>
      </div>
    </van-dialog> -->

    <van-dialog
      style="background: transparent"
      v-model="showposter"
      :showConfirmButton="false"
    >
      <div class="poster">
        <img
          class="posterimg"
          src="https://oss.oxke.net/boxuanedu-m/home/bgvideom.png"
          alt="poster"
        />
        <img
          class="postercloseimg videoclose"
          src="https://oss.oxke.net/boxuanedu/home/floatbox/videoclose.png"
          alt="posterclose"
          @click="showposter = false"
        />
        <div class="content">
          <video
            controls="controls"
            autoplay="autoplay"
            name="media"
            class="boxuanvideo"
          >
            <source
              src="https://oss.oxke.net/boxuanedu/boxuan.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  NoticeBar,
  Swipe,
  SwipeItem,
  Icon,
  Search,
  DropdownMenu,
  DropdownItem,
  Popup,
  Tabbar,
  TabbarItem,
  Popover,
  Notify,
  Dialog,
} from "vant";
import axios from "axios";
import hometitle from "./components/hometitle.vue";
import trylessoncard from "./components/trylessoncard.vue";
import hotlessoncard from "./components/hotlessoncard.vue";
import preferlessoncard from "./components/preferlessoncard.vue";
import myfooter from "./headfooter/foot.vue";

/* 临时数据 */
import {
  preferlessons,
  hotlessons,
  hometitles,
  limitlessons,
} from "../tempdata/home/homelessons";

/* api */
import { getschoolinfo, getexaminfo, queryexaminfo } from "../api/index.js";

export default {
  name: "Home",
  data() {
    return {
      showPopover: false,
      actions: [
        { text: "首页" },
        { text: "师资信息" },
        { text: "关于我们" },
        { text: "加入我们" },
      ],
      /* 首页标题设置 */
      hometitles,
      /* 下拉菜单 */
      value1: -1,
      value2: -1,
      eaxmtag: [{ text: "考试类型", value: -1 }],
      schools: [{ text: "省份", value: -1 }],
      theschools: "",
      thexamtag: "",

      /* 海报弹出 */
      showposter: false,

      /* 首页tag菜单设置 */
      tagsbanner1: [
        {
          img: "https://oss.oxke.net/boxuanedu-m/home/tagsbanner/tag1",
          title: "全部",
        },
        {
          img: "https://oss.oxke.net/boxuanedu-m/home/tagsbanner/tag2",
          title: "热门课程",
        },
        {
          img: "https://oss.oxke.net/boxuanedu-m/home/tagsbanner/tag3",
          title: "优选课程",
        },

        {
          img: "https://oss.oxke.net/boxuanedu-m/home/tagsbanner/tag4",
          title: "选调生",
        },
        {
          img: "https://oss.oxke.net/boxuanedu-m/home/tagsbanner/tag5",
          title: "事业编",
        },
      ],
      tagsbanner2: [
        {
          img: "https://oss.oxke.net/boxuanedu-m/home/tagsbanner/tag6",
          title: "医疗",
        },

        {
          img: "https://oss.oxke.net/boxuanedu-m/home/tagsbanner/tag7",
          title: "三支一扶",
        },
        {
          img: "https://oss.oxke.net/boxuanedu-m/home/tagsbanner/tag8",
          title: "银行金融",
        },

        {
          img: "https://oss.oxke.net/boxuanedu-m/home/tagsbanner/tag9",
          title: "教师编",
        },
        {
          img: "https://oss.oxke.net/boxuanedu-m/home/tagsbanner/tag10",
          title: "公务员",
        },
      ],

      /* 试听课程配置 */
      // trylessons: [
      //   {
      //     img: require("../assets/img/home/try/组 2002.png"),
      //     auth: require("../assets/img/home/try/组 8.png"),
      //     lessontitle: "行测-判断技巧",
      //     authname: "小路",
      //   },
      //   {
      //     img: require("../assets/img/home/try/组 2003.png"),
      //     auth: require("../assets/img/home/try/组 8.png"),
      //     lessontitle: "行测-速算技巧",
      //     authname: "小路",
      //   },
      //   {
      //     img: require("../assets/img/home/try/组 2004.png"),
      //     auth: require("../assets/img/home/try/组 8.png"),
      //     lessontitle: "行测-判断技巧",
      //     authname: "小路",
      //   },
      //   {
      //     img: require("../assets/img/home/try/组 2002.png"),
      //     auth: require("../assets/img/home/try/组 8.png"),
      //     lessontitle: "行测-判断技巧",
      //     authname: "小路",
      //   },
      //   {
      //     img: require("../assets/img/home/try/组 2003.png"),
      //     auth: require("../assets/img/home/try/组 8.png"),
      //     lessontitle: "行测-判断技巧",
      //     authname: "小路",
      //   },
      //   {
      //     img: require("../assets/img/home/try/组 2004.png"),
      //     auth: require("../assets/img/home/try/组 8.png"),
      //     lessontitle: "行测-判断技巧",
      //     authname: "小路",
      //   },
      // ],

      /* 限时优惠课程设置 */
      limitlessons,

      /* 优选课程配置 */
      preferlessons,

      /* 热门课程配置 */
      hotlessons,

      lessonstag: [
        "公考笔试",
        "公考面试",
        "教师",
        "军队文职",
        "三支一扶",
        "公安招警",
        "银行金融",
        "社区工作者",
        "医疗",
        "财汇",
        "考研",
        "法考",
        "建工",
        "公开课",
        "Office",
        "英语",
        "专升本",
      ],
      show: 0,
      examinfolist: [],
      examdatalist: [],
      allexaminfolist: [],
    };
  },
  components: {
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Icon.name]: Icon,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Popup.name]: Popup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Popover.name]: Popover,
    [Notify.name]: Notify,
    [Dialog.Component.name]: Dialog.Component,
    hometitle,
    trylessoncard,
    hotlessoncard,
    preferlessoncard,
    myfooter,
  },
  activated() {
    this.$navBar.setTitle("");

    // 设置dot和badge
    // this.$tabBar.setDot("HOME", true);
    // this.$tabBar.setBadge("ABOUT", "100");
  },
  created() {
    this.getdata();
    // this.getexamdatalist();
    getschoolinfo();
    getexaminfo();
  },
  mounted() {
    setTimeout(() => {
      this.setoption();
      this.showposter = true;
    }, 1000);
  },
  methods: {
    /* 弹出动画设置 */
    /* 获取考试信息 */
    getdata() {
      if (sessionStorage.getItem("examinfolist")) {
        let list = JSON.parse(sessionStorage.getItem("examinfolist"));

        for (let index = 0; index <= 2; index++) {
          this.examinfolist.push(list[index]);
        }
        return;
      } else {
        const url = "https://das.boxuanedu.cn/dd/rest/courseDetail/list";
        axios
          .post(url, {
            pageNum: 10,
            startPage: 0,
          })
          .then((res) => {
            let list = res.data.data.list;
            for (let index = 0; index <= 2; index++) {
              this.examinfolist.push(list[index]);
            }
            // this.examinfolist = list.splice(0, 3);
            // this.allexaminfolist = res.data.data.list;
            sessionStorage.setItem("examinfolist", JSON.stringify(list));
          })
          .catch((err) => console.log(err));
      }
    },
    getexamdatalist() {
      if (sessionStorage.getItem("examdatalist")) {
        let list = JSON.parse(sessionStorage.getItem("examdatalist"));
        this.examdatalist = list.splice(0, 3);
        return;
      }
      const url = "https://das.boxuanedu.cn/dd/rest/articleInfo/export";
      axios
        .post(url)
        .then((res) => {
          let list = res.data.data.list;
          this.examdatalist = list.splice(0, 3);
          sessionStorage.setItem("examdatalist", JSON.stringify(list));
        })
        .catch((err) => console.log(err));
    },
    todetail(pathname, id) {
      this.$router.push({
        name: pathname,
        query: { id },
        params: {
          allexaminfolist: this.allexaminfolist,
        },
      });
    },

    /* 进入课程汇总页 */
    tolesson(tagname) {
      this.$router.push({ name: "lesson", query: { tagname } });
    },
    /* 进入课程详情页 */
    tolessoninfo(code, type) {
      if (code == "EDDn" || code == "M0xg") {
        this.$router.push({ name: "pubcourse" });
        return;
      }
      this.$router.push({ name: "lessoninfo", query: { code, type } });
    },
    /* 主页导航 */
    to(actions, index) {
      if (index == 0) {
        this.$router.push({ name: "Home" });
      }
      if (index == 1) {
        this.$router.push({ name: "teacher" });
      }
      if (index == 2) {
        this.$router.push({ name: "about" });
      }
      if (index == 3) {
        this.$router.push({ name: "recruit" });
      }
    },
    /* 考试信息选项数据--省份 */
    setoption() {
      const schoolslist = JSON.parse(sessionStorage.getItem("schools"));
      const exmataglist = JSON.parse(sessionStorage.getItem("exmatag"));

      for (const key in schoolslist) {
        this.schools.push({
          text: schoolslist[key].province,
          value: schoolslist[key].province,
        });
      }

      for (const key in exmataglist) {
        this.eaxmtag.push({
          text: exmataglist[key].cataName,
          value: exmataglist[key].id,
        });
      }
    },
    /* 获取下拉菜单数据 */
    async getexamtag(value) {
      this.thexamtag = value;
      const cataId = this.thexamtag;
      const province = this.theschools;

      const end = await queryexaminfo(cataId, province);

      const data = end.data.data.list;

      this.examinfolist = data.splice(0, 3);
      if (this.examinfolist.length == 0) {
        Notify({
          type: "success",
          message: `该类别信息正在准备中...敬请期待`,
          duration: 1000,
        });
      }
    },
    async getexamprovice(value) {
      this.theschools = value;
      const province = this.theschools;
      const cataId = this.thexamtag;

      const end = await queryexaminfo(cataId, province);
      const data = end.data.data.list;

      this.examinfolist = data.splice(0, 3);
      if (this.examinfolist.length == 0) {
        Notify({
          type: "success",
          message: `该类别信息正在准备中...敬请期待`,
          duration: 1000,
        });
      }
    },

    /* 海报跳转 */
    postergo() {
      this.$router.push({ name: "recruit" });
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/mixins.less";

.home {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .topcontent {
    width: 100%;
    height: 128px;
    background: url("https://oss.oxke.net/boxuanedu-m/home/topbg.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ourlogo {
      width: 140px;
      margin-left: 10px;
      position: relative;
    }

    .nav {
      position: absolute;

      left: 87%;
      top: 20px;
      .navpanple {
        position: relative;
        width: 25px;
        left: 12px;
        top: 7px;
        border-radius: 8px;
      }
    }

    .lessonclassfiy {
      width: 100%;
      overflow: auto;
      display: flex;
      padding: 10px;

      background: #fff;
      p {
        flex: none;
        font-size: 16px;
        color: #8a9095;
        margin-right: 12px;
        padding: 0 0.13333rem;
      }
    }
    .content {
      position: relative;
      top: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      height: 77%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .cardshow {
        padding: 10px;
        width: 90%;
      }
    }

    /* pad型号临时适配 */
    @media screen and (min-width: 518px) and (max-width: 653px) {
      .content {
        position: relative;
        top: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        height: 70%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .cardshow {
          padding: 10px;
          width: 90%;
        }
      }
    }

    @media screen and (min-width: 654px) {
      .content {
        position: relative;
        top: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        height: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .cardshow {
          padding: 10px;
          width: 90%;
        }
      }
    }
  }
  .topbanner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .imgbanner {
      position: relative;
      top: -14px;
      width: 354px;
      border-radius: 8px;
    }
  }

  .tagsbanner {
    width: 100%;
    height: 80px;
    .tagcontent {
      height: 80px;
      display: flex;
      justify-content: space-evenly;
      text-align: center;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #2d2d2d;
      .item {
        .tagimg {
          width: 42px;
          height: 42px;
        }
      }
    }
  }

  .exam {
    .examtags {
      width: 100%;
      padding: 12px 10px 0 10px;
      /deep/.van-dropdown-menu__bar {
        position: relative;
        display: flex;
        height: 8.8vw;
        background-color: #f5f5f5;
        box-shadow: none;
        border-radius: 2.133333vw;
      }
    }

    .examinfom {
      padding: 12px 10px 0px 10px;
      .item {
        border: 1px solid #dcebfd;
        border-radius: 12px;
        padding: 10px;
        .itemtitle {
          color: #020b10;
          font-weight: 500;
          font-size: 16px;
          font-family: "Roboto", sans-serif;
          margin-bottom: 8px;
        }
        .itemtime {
          font-weight: 400;
          margin-bottom: 10px;
          font-size: 12px;
          color: #0935d6;
        }
        .itemposition {
          display: flex;
          border-radius: 4px 4px 4px 4px;

          font-size: 10px;
          color: #565656;
          .tag {
            border-radius: 4px;
            padding: 5px;
            background: #e6e6e6;
          }
        }
      }
    }
  }

  .try {
    .trycontent {
      display: flex;
      width: 100%;
      margin-top: 16px;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  }

  .prefer {
    .preferlessoncontent {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .hot {
    .hotcontent {
      display: flex;
      margin-top: 16px;
      flex-wrap: wrap;
      margin-left: 12px;
    }
  }

  .teachersintro {
    .swipecontent {
      width: 100%;
      height: 150px;
      padding: 10px;
      padding-top: 0;
      position: relative;
      top: -15px;
      .my-swipe .van-swipe-item {
        .hotimg {
          width: 354px;
          object-fit: contain;
        }
      }
    }

    .publicizecontent {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 360px;
      padding: 10px;
      padding-top: 0;
      .item {
        height: 118px;
        background: #f9f9f9;
        margin-bottom: 9px;
        border-radius: 6px;
        box-sizing: border-box;
        padding: 14px 0px 16px 15px;
        .inforp {
          box-sizing: border-box;
          .num {
            font-size: 21px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            display: flex;
            margin-bottom: 12px;
            align-items: baseline;
            .numafter {
              font-size: 15px;
              font-family: Source Han Sans CN-Bold, Source Han Sans CN;
              font-weight: bold;
              color: #141414;
              line-height: 24px;
              margin-left: 12px;
            }
          }
          .commonp {
            display: flex;
            align-items: flex-end;
            .common {
              font-size: 15px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #141414;
            }
            .commonafter {
              font-size: 14px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #8e8e8e;
              margin-left: 16px;
            }
          }
        }
      }
    }
  }

  .ourinfo {
    .infocontent {
      display: flex;
      flex-direction: column;
      padding: 10px;
      margin-top: 9px;
      .aim {
        font-size: 15px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #0f67ec;
        text-align: center;
        margin-bottom: 12px;
      }

      .ourdes {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 21px;
        margin-top: 12px;
      }

      .infotitle {
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #0f67ec;
        margin-top: 24px;
        margin-bottom: 18px;
      }

      .businessp {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        display: flex;
        position: relative;
        margin-bottom: 16px;
      }
      .businessp::before {
        content: "";
        display: block;
        width: 15px;
        height: 8px;
        background: #0f67ec;
        margin-right: 5px;
        position: relative;
        top: 3px;
        border-radius: 50px;
      }

      .tagcontent {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .tag {
          width: 128px;
          height: 40px;
          display: flex;
          margin-bottom: 16px;
          align-items: center;
          .tagimg {
            width: 33px;
            height: 33px;
            margin-right: 3px;
          }
          .taginfo {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .up {
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141414;
              margin-bottom: 5px;
            }
            .bottom {
              font-size: 10px;
              font-family: Microsoft YaHei-Light, Microsoft YaHei;
              font-weight: 300;
              color: #141414;
            }
          }
        }
      }
    }
    .bg {
      width: 100%;
      height: 85px;
      background: url("https://oss.oxke.net/boxuanedu-m/home/our/bg2.png");
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        height: 31px;
        margin-bottom: 6px;
      }
      p {
        font-size: 5px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #163c5a;
        letter-spacing: 5px;
      }
    }
    .ourintro {
      display: flex;
      flex-direction: column;
      padding: 10px;
      padding-top: 0;
      .smallintro {
        height: 184px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(49, 120, 228, 0.26);
        border-radius: 6px 6px 6px 6px;
        opacity: 0.86;
        padding: 12px 21px 22px 15px;

        margin-bottom: 14px;
        .title {
          font-size: 14px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #202020;
          margin-bottom: 7px;
        }
        .theintro {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #223341;
          line-height: 18px;
        }
      }
      .ourdirection {
        height: 83px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 1px rgba(20, 130, 255, 0.15);
        background: url("https://oss.oxke.net/boxuanedu-m/home/our/2.png");
        background-size: cover;
        margin-bottom: 11px;
        padding: 10px 16px 16px 10px;
        display: flex;
        align-items: center;
        .theimg {
          width: 46px;
          height: 46px;
          margin-right: 10px;
        }
        .theinfo {
          height: 64px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .title {
            font-size: 14px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #223341;
          }
          .info {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #223341;
            line-height: 18px;
          }
        }
      }
    }
  }

  .footerer {
    display: flex;
    flex-direction: column;
    background: #6e6e6e;
    width: 100%;
    height: 90px;
    align-items: center;
    justify-content: space-evenly;
    p {
      font-size: 8px;
      color: #fff;
      transform: scale(0.85);
    }
  }

  .poster {
    position: relative;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    .posterimg {
      width: 100%;
      object-fit: contain;
    }
    .postercloseimg {
      width: 25px;
      height: 25px;
      object-fit: contain;
      position: absolute;
      top: 40px;
      right: 10px;
    }
    .content {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 175px;
      left: 58px;
      h1 .keyspan {
        transition: all 1s ease;
        color: #0036ff;
        opacity: 0;
        animation: keyspanop 1s linear 0.5s forwards;
      }
      p {
        margin: 10px 0;
        font-size: 20px;
        text-align: center;
      }
      .btn {
        width: 120%;
        margin-top: 10px;
        padding: 10px;
        background-image: linear-gradient(to right, #0983ee 0%, #03eefa 100%);
        border-radius: 24px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 16px;
        animation: bigbtn 1s infinite;
        cursor: pointer;
        left: -15px;
      }
      @keyframes bigbtn {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes keyspanop {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }
  }
}

.boxuanvideo {
  width: 224px;
  position: relative;
  top: -4px;
  left: -10px;
  z-index: 1000;
}

.videoclose {
  position: relative;
  top: 30px;
  right: 20px;
}

/* home 全局css配置 */
.line {
  width: 100vw;
  height: 6px;
  background: #e3dfdf;
  margin-top: 12px;
  margin-bottom: 6px;
}

.van-search {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 2.666667vw 3.2vw;
  background-color: transparent;
}

.colorblue {
  color: #0f67ec;
}

.colororg {
  color: rgba(236, 126, 15, 1);
}

.colorgreen {
  color: rgb(8, 214, 36);
}

.commbottom {
  margin-bottom: 12px;
}

.rightnav {
  width: 120px;
  height: 100vh;
  background: #fff;
  .item {
    padding: 20px;
  }
  .logo {
    position: absolute;
    bottom: 20px;
    left: 30px;
  }
}

/**/
/deep/.van-swipe__indicators {
  position: absolute;
  bottom: 1.2vw;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  background: transparent;
  border-radius: 12px;
}
</style>
